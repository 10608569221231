.form {
  margin-top: 1rem;
}

.dropdown-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 70%;
  padding-bottom: 1rem;
}

.dropdown-wrapper .dropdown-label {
  width: 40%;
  font-size: 1rem;
  margin-right: 40px;
}

.dropdown-wrapper .fui-Dropdown {
  width: 100%;
  max-width: 50%;
}

.dropdown-wrapper .spinner {
  flex-grow: 1;
}

.filters-loading {
  display: flex;
  gap: 10px;
  vertical-align: middle;

  .spinner {
    padding-top: 3px;
  }
}


.publisher-option-group .fui-OptionGroup__label {
  display: flex;
  font-size: 0.875rem !important;
}

.publisher-option-group .publisher-option-group-id {
  font-weight: bold;
}

.thread-select-switch .fui-Label {
  font-size: 1rem;
}

.thread-input {
  width: 50%;
}

.button-wrapper {
  display: flex;
  justify-content: flex-end;
  max-width: 70%;
  margin: 1rem 0;
}