.no-data {
  display: flex;
  justify-content: center;
  padding: 3rem;
  font-size: 1.5rem;
}

.spinner-wrapper {
  margin-top: 2rem;
}
