.center {
  text-align: center;
}

.sections > * {
  margin: 4rem auto;
}

.page-heading {
  font-size: 1.5rem;
}

.page-padding {
  padding: 0.5rem 1rem;
}

pre,
div.error {
  background-color: #e5e5e5;
  padding: 1rem;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  margin: 1rem 0;
  max-height: 200px;
  overflow-x: scroll;
  overflow-y: scroll;
  max-width: 732px;
}

pre.fixed,
div.error.fixed {
  height: 200px;
}

code {
  background-color: #e5e5e5;
  display: inline-block;
  padding: 0px 6px;
  border-radius: 3px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
}

code::-webkit-scrollbar,
pre::-webkit-scrollbar {
  display: none;
}

.contrast pre,
.contrast code,
.contrast div.error {
  background-color: #000000;
  border-color: #ffffff;
  border-width: thin;
  border-style: solid;
}

.dark,
.light {
  background-repeat: no-repeat !important;
  background-position: right bottom !important;
}

.light {
  background: url('../assets/bg-light.png');
  background-color: #ffffff;
}

.dark {
  background: url('../assets/bg-dark.png');
  background-color: #1f1f1f;
}

.dark pre,
.dark code,
.dark div.error {
  background-color: #1b1b1b;
}

.error {
  color: red;
}

.tab-container {
  min-height: 100%;
}

.tab-container .page-heading {
  margin-top: 0.5rem;
  padding-bottom: 1rem;
}

.dg-header-cell {
  font-weight: bold !important;
}

.heading-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.id-cell {
  flex: 0 0 6% !important;
}

.project-cell {
  flex: 0 0 20% !important;
}

.event-cell {
  flex: 0 0 25% !important;
}

.thread-cell {
  flex: 0 0 20% !important;
}

.modifiedby-cell {
  flex: 0 0 16% !important;
}

.actions-cell {
  flex: 0 0 13% !important;
}

.action-button {
  margin-right: 5px !important;
  /* padding-left: 2px !important; */
}